<template>
  <v-app>
    <v-app-bar app color="primary" class="white--text">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="white--text"
      ></v-app-bar-nav-icon>
      <v-img
        src="/img/logo/logo-kota.png"
        max-width="40px"
        class="mb-1"
      ></v-img>
      <v-toolbar-title to="/">
        <strong>&nbsp;&nbsp;{{ appNameDetail }}</strong>
      </v-toolbar-title>
      <!-- pemisah konten -->
      <v-spacer></v-spacer>
      <template v-if="!guest">
        <div class="pa-2">
          <v-btn color="secondary" @click="logout" rounded small>
            <v-icon left small>mdi-logout-variant</v-icon>
            Logout
          </v-btn>
        </div>
      </template>
      <!-- <template v-else>
        <v-btn
          color="secondary"
          @click="setDialogComponent('login')"
          rounded
          small
        >
          <v-icon left small>mdi-login-variant</v-icon>
          Login
        </v-btn>
      </template> -->
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer">
      <v-container>
        <v-fade-transition mode="out-in">
          <v-row class="mt-2">
            <v-col cols="12" align="center">
              <!-- <v-img src="/img/logo/logopajak.png" style="width: 100%"></v-img> -->
              <v-img src="/img/logo/logo-kota.png" style="width: 45%"></v-img>
            </v-col>
          </v-row>
        </v-fade-transition>
      </v-container>
      <div class="text-center mb-2" style="color: black" v-if="guest">
        <b>{{ appNameNormal }}</b
        ><br /><small>KOTA GORONTALO</small>
      </div>
      <!-- NAMA USER -->
      <div class="text-center mb-2" style="color: black" v-if="!guest">
        <b>{{ user.data.full_name }}</b
        ><br /><small>{{ user.data.email }}</small>
      </div>

      <v-divider></v-divider>

      <!-- BELUM LOGIN -->
      <v-list v-if="guest" dense>
        <v-list-item
          v-for="(item, index) in menus"
          :key="`menu-` + index"
          :to="item.route"
          color="primary"
        >
          <v-list-item-icon>
            <v-icon left>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="ml-n4">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- SUDAH LOGIN -->
      <div v-if="!guest">
        <!-- ROLE 1 ADMIN -->
        <v-list v-if="this.user.data.role == '1'" dense>
          <!-- Menu 1 -->
          <v-list-item
            v-for="(item, index) in menuAdmin"
            :key="`menu-` + index"
            :to="item.route"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="ml-n4">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-for="item in itemsSSO"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content class="ml-n4">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              :to="child.route"
            >
              <v-list-item-content class="ml-n4">
                <v-list-item-title>{{ child.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>

        <!-- ROLE 2 ADMIN -->
        <v-list v-else-if="this.user.data.role == '2'" dense>
          <!-- Menu 1 -->
          <v-list-item
            v-for="(item, index) in menuAdminKenpa"
            :key="`menu-` + index"
            :to="item.route"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon left>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Data Master -->
          <v-list-group
            v-for="item in menuAdminKenpaMaster"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content class="ml-2">
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              :to="child.route"
            >
              <v-list-item-content class="ml-2">
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>

        <!-- ROLE 2 ADMIN -->
        <v-list v-else-if="this.user.data.role == '4'" dense>
          <!-- Menu 1 -->
          <v-list-item
            v-for="(item, index) in menuAdminStatistik"
            :key="`menu-` + index"
            :to="item.route"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon left>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list v-else-if="this.user.data.role == '5'" dense>
          <v-list-item
            v-for="(item, index) in menuAdminPenilaian"
            :key="`menu-` + index"
            :to="item.route"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon left>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <!-- ROLE SELAIN ADMIN -->
        <v-list v-else dense>
          <v-list-item
            v-for="(item, index) in menuPegawai"
            :key="`menu-` + index"
            :to="item.route"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon left>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>

    <alert />

    <keep-alive>
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialogbottom-transition"
      >
        <component :is="currentComponent" @closed="setDialogStatus"></component>
      </v-dialog>
    </keep-alive>

    <v-main class="bgcolor">
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer v-if="!guest" color="primary" class="white--text">
      <v-card-text class="text-center">
        &copy; {{ new Date().getFullYear() }} —
        <strong>{{ appNameDetail }}</strong>
      </v-card-text>
    </v-footer>
    <v-footer v-else color="primary" class="white--text">
      <v-card-text class="text-center" @click="setDialogComponent('login')">
        &copy; {{ new Date().getFullYear() }} —
        <strong>{{ appNameDetail }}</strong>
      </v-card-text>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    Alert: () =>
      import(/* webpackChunkName: "alert" */ "@/components/Alert.vue"),
    Search: () =>
      import(/* webpackChunkName: "search" */ "@/components/Search.vue"),
    Login: () => import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
    Register: () =>
      import(/* webpackChunkName: "login" */ "@/views/Register.vue"),
    Cart: () => import(/* webpackChunkName: "cart" */ "@/components/Cart.vue"),
  },
  data: () => ({
    drawer: true,
    menus: [
      { title: "Home", icon: "mdi-home", route: "/" },
      {
        title: "Daftar Operator SIMPEL-ASN",
        icon: "mdi-account-plus",
        route: "/daftar-operator",
      },
    ],

    menuAdmin: [
      { title: "HOME", icon: "mdi-home", route: "/" },
      // { title: "Daftar Hadir", icon: "mdi-view-list", route: "/absensi" },
      // { title: "Kegiatan", icon: "mdi-calendar-edit", route: "/kegiatan" },
      // { title: "Dashboard", icon: "mdi-view-dashboard", route: "/dashboard" },
      // { title: "Produk", icon: "mdi-cube-scan", route: "/admin-produk" },
      // { title: "Transaksi", icon: "mdi-shopping", route: "/transactions" },
    ],

    menuAdminKenpa: [
      { title: "Home", icon: "mdi-home", route: "/" },
      {
        title: "Registrasi KENPA",
        icon: "mdi-book-plus-multiple",
        route: "/kenpa",
      },
    ],

    menuAdminKenpaMaster: [
      {
        action: "mdi-database",
        items: [
          { title: "Jenis KENPA", route: "/jenis-kenpa" },
          { title: "Verifikator", route: "/verifikator" },
        ],
        title: "Referensi",
      },
    ],

    menuAdminStatistik: [
      { title: "Home", icon: "mdi-home", route: "/" },
      {
        title: "Statistik Pendidikan",
        icon: "mdi-school",
        route: "/statistik-pendidikan",
      },
      {
        title: "Statistik Jenis Kelamin",
        icon: "mdi-gender-male-female",
        route: "/statistik-jenis-kelamin",
      },
      {
        title: "Statistik Eselon",
        icon: "mdi-chandelier",
        route: "/statistik-eselon",
      },
      {
        title: "Statistik Golongan Ruang",
        icon: "mdi-lightbulb-group",
        route: "/statistik-golongan-ruang",
      },
      {
        title: "Statistik Jenis Jabatan",
        icon: "mdi-shape",
        route: "/statistik-jenis-jabatan",
      },
    ],

    menuAdminPenilaian: [
      { title: "Home", icon: "mdi-home", route: "/" },
      {
        title: "Penilaian Kompetensi",
        icon: "mdi-brightness-percent",
        route: "/penilaian-kompetensi",
      },
    ],

    menuPegawai: [
      { title: "Home", icon: "mdi-home", route: "/" },
      // { title: "My Order", icon: "mdi-shopping", route: "/my-order" },
      // { title: "My Profile", icon: "mdi-account", route: "/my-profile" },
      // { title: "Transaksi", icon: "mdi-shopping", route: "/transactions" },
    ],

    itemsSSO: [
      {
        action: "mdi-view-list",
        items: [
          { title: "Daftar Hadir", route: "/absensi" },
          { title: "Kegiatan", route: "/kegiatan" },
        ],
        title: "DAFTAR HADIR",
      },
      {
        action: "mdi-list-box",
        items: [
          { title: "Kuesioner", route: "/kuesioner" },
          { title: "Bidang", route: "/bidang" },
        ],
        title: "IKM",
      },
      {
        action: "mdi-book-plus-multiple",
        items: [
          {
            title: "Registrasi KENPA",
            route: "/kenpa",
          },
          { title: "Jenis KENPA", route: "/jenis-kenpa" },
          { title: "Verifikator", route: "/verifikator" },
        ],
        title: "KENPA",
      },
      {
        action: "mdi-chart-bar",
        items: [
          {
            title: "Statistik Pendidikan",
            route: "/statistik-pendidikan",
          },
          {
            title: "Statistik Jenis Kelamin",
            route: "/statistik-jenis-kelamin",
          },
          {
            title: "Statistik Eselon",
            route: "/statistik-eselon",
          },
          {
            title: "Statistik Golongan Ruang",
            route: "/statistik-golongan-ruang",
          },
          {
            title: "Statistik Jenis Jabatan",
            route: "/statistik-jenis-jabatan",
          },
        ],
        title: "STATISTIK 5 TAHUN",
      },
      {
        action: "mdi-brightness-percent",
        items: [
          {
            title: "Penilaian Kompetensi",
            route: "/penilaian-kompetensi",
          },
        ],
        title: "PENILAIAN KOMPETENSI",
      },
      {
        action: "mdi-account-group",
        items: [
          {
            title: "Daftar Operator SIMPEL-ASN",
            route: "/simpelasn-operator",
          },
          {
            title: "Setting Operator SKPD",
            route: "/setting-operator",
          },
        ],
        title: "OPERATOR SIMPEL-ASN",
      },
    ],
  }),
  methods: {
    ...mapActions({
      setDialogStatus: "dialog/setStatus",
      setDialogComponent: "dialog/setComponent",
      setAlert: "alert/set", // <=
      setAuth: "auth/set", // <=
    }),
    logout() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .post("/auth/logout", {}, config)
        .then(() => {
          this.$vloading.hide();
          this.setAuth({}); // kosongkan auth ketika logout
          this.setAlert({
            status: true,
            color: "success",
            text: "Logout successfully",
          });
          this.$router.replace({ name: "Home" });
        })
        .catch((error) => {
          this.$vloading.hide();
          let { data } = error.response;
          console.log(data.code);
          if (data.code == 500) {
            this.setAuth({}); // kosongkan auth ketika logout
            this.setAlert({
              status: true,
              color: "success",
              text: "Logout successfully",
            });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: data.message,
            });
          }
        });
    },
  },
  computed: {
    isHome() {
      return this.$route.path === "/";
    },
    ...mapGetters({
      countCart: "cart/count",
      guest: "auth/guest",
      user: "auth/user",
      dialogStatus: "dialog/status",
      currentComponent: "dialog/component",
    }),
    dialog: {
      get() {
        return this.dialogStatus;
      },
      set(value) {
        this.setDialogStatus(value);
      },
    },
  },
};
</script>
