import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },

  // DAFTAR HADIR
  {
    path: "/kegiatan",
    name: "Kegiatan",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/admin/Kegiatan.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/absensi",
    name: "Absensi",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/admin/Absensi.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/absensi-detail",
    name: "Absensi Detail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/AbsensiDetail.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/forms/:id",
    name: "Form Daftar Hadir",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Forms.vue"),
  },

  // STATISTIK 5 TAHUN
  {
    path: "/statistik-pendidikan",
    name: "Statistik Pendidikan",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/adminstatistik/StatistikPendidikan.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/statistik-jenis-kelamin",
    name: "Statistik Jenkel",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/adminstatistik/StatistikJenkel.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/statistik-eselon",
    name: "Statistik Eselon",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/adminstatistik/StatistikEselon.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/statistik-golongan-ruang",
    name: "Statistik Golongan Ruang",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/adminstatistik/StatistikGolruang.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/statistik-jenis-jabatan",
    name: "Statistik Jenis Jabatan",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/adminstatistik/StatistikJenjab.vue"
      ),
    meta: {
      auth: true,
    },
  },

  // IKM
  {
    path: "/kuesioner",
    name: "Kuesioner",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/adminikm/Kuesioner.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/statistik",
    name: "Statistik",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/adminikm/Statistik.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/statistik-pdf",
    name: "Statistik PDF",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/adminikm/StatistikPDF.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/statistik-detail",
    name: "Statistik Detail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/adminikm/StatistikDetail.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/pertanyaan",
    name: "Pertanyaan",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/adminikm/Pertanyaan.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/bidang",
    name: "Bidang",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/adminikm/Bidang.vue"),
    meta: {
      auth: true,
    },
  },

  // KENPA
  {
    path: "/kenpa",
    name: "Kenpa",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/adminkenpa/Filter.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/registrasi-kenpa",
    name: "Registrasi Kenpa",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/adminkenpa/RegistrasiKenpa.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/jenis-kenpa",
    name: "Jenis Kenpa",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/adminkenpa/JenisKenpa.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/verifikator",
    name: "Verifikator",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/adminkenpa/Verifikator.vue"
      ),
    meta: {
      auth: true,
    },
  },

  // PENILAIAN KOMPETENSI
  {
    path: "/penilaian-kompetensi",
    name: "Penilaian Kompetensi",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/adminpenilaian/PenilaianKompetensi.vue"
      ),
    meta: {
      auth: true,
    },
  },

  // SIMPEL-ASN OPERATOR
  {
    path: "/simpelasn-operator",
    name: "Simpelasn Operator",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/adminsimpegoperator/SimpegOperator.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/daftar-operator",
    name: "Daftar Operator",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/adminsimpegoperator/AddSimpegOperator.vue"
      ),
  },
  {
    path: "/setting-operator",
    name: "Setting Operator",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/adminsimpegoperator/SimpegUnorSetting.vue"
      ),
  },

  // OTHER
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/categories",
    name: "categories",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Categories.vue"),
  },
  {
    path: "/products",
    name: "products",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Products.vue"),
  },
  {
    path: "/category/:slug",
    name: "category",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Category.vue"),
  },
  {
    path: "/product/:slug",
    name: "product",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Product.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// tambahkan ini untuk melakukan pengecekan pada setiap routing
router.beforeEach((to, from, next) => {
  // jika routing ada meta auth-nya maka
  if (to.matched.some((record) => record.meta.auth)) {
    // jika user adalah guest
    if (store.getters["auth/guest"]) {
      // tampilkan pesan bahwa harus login dulu
      store.dispatch("alert/set", {
        status: true,
        text: "Login first",
        color: "error",
      });
      // tampilkan form login
      store.dispatch("dialog/setComponent", "login");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
