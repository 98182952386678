<template>
  <!-- <div>
    <v-container class="mt-2" grid-list-sm>
      <div class="text-left pb-3 pl-14">
        <v-hover v-slot="{ hover }">
          <v-btn
            small
            to="/categories"
            color="info"
            class="pa-6 px-12"
            :elevation="hover ? 10 : 2"
            :class="{ 'on-hover': hover }"
          >
            <v-icon left>mdi-clipboard-text</v-icon>Kategori</v-btn
          >
        </v-hover>
      </div>
      <v-sheet class="mx-auto" max-width="100%">
        <v-slide-group v-model="model" active-class="success" show-arrows>
          <v-slide-item
            v-for="category in categories"
            :key="`category-` + category.id"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                :to="'/category/' + category.id"
                class="ma-1"
                :elevation="hover ? 10 : 2"
                :class="{ 'on-hover': hover }"
              >
                <v-card-title
                  ><v-avatar size="35">
                    <v-img
                      :src="getIcon(category.icon)"
                      style="width: 100%"
                    ></v-img>
                  </v-avatar>
                  &nbsp; <small>{{ category.category }}</small></v-card-title
                >
              </v-card>
            </v-hover>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </v-container>
    <v-container class="mt-4" grid-list-sm>
      <div class="pb-2 d-flex justify-space-between">
        <div>
          <span style="font-size: 15pt"
            ><strong>Produk terpopuler</strong></span
          >
        </div>
        <div>
          <v-btn small text to="/products" class="blue--text">
            Lihat Semua <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
      <v-layout wrap>
        <v-flex
          v-for="product in productsPopuler"
          :key="`product-` + product.product_id"
          xs6
          sm6
          md3
          lg3
          class="pa-1 pa-md-2 pa-lg-2"
        >
          <book-item :product="product" />
        </v-flex>
      </v-layout>
      </v-container
    ><br />
    <v-container class="mt-2" grid-list-sm>
      <div class="pb-2 d-flex justify-space-between">
        <div>
          <span style="font-size: 15pt"><strong>Produk terbaru</strong></span>
        </div>
        <div>
          <v-btn small text to="/products" class="blue--text">
            Lihat Semua <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
      <v-layout wrap>
        <v-flex
          v-for="product in productsLatest"
          :key="`product-` + product.product_id"
          xs6
          sm6
          md3
          lg3
          class="pa-1 pa-md-2 pa-lg-2"
        >
          <book-item :product="product" />
        </v-flex>
      </v-layout>
    </v-container>
  </div> -->
  <div>
    <v-alert color="primary" dark border="top" prominent class="text-center">
      <b
        >Selamat Datang di Website {{ appNameDetail }}. <br />
        Terima kasih telah berpartisipasi.</b
      >
    </v-alert>
  </div>
</template>

<script>
export default {
  name: "Home",
  // components: {
  //   BookItem: () =>
  //     import(/* webpackChunkName: "book-item" */ "@/components/BookItem.vue"),
  // },
  data: () => ({
    model: null,
    categories: [],
    productsPopuler: [],
    productsLatest: [],
  }),
  created() {},
};
</script>
